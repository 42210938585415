<template>
  <div id="form-experiment-detail">
    <v-row class="mt-4">
      <v-col cols="9">
        <h3>Experiments</h3>
      </v-col>
      <v-col v-if="experimentState === states.DRAFT" class="text-end" cols="3">
        <v-btn prepend-icon="mdi-square-edit-outline" class="text-none font-weight-bold" color="primary-red"
          @click="toggleDialog()"> Edit Experiment</v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <div class="mt-4" v-if="experimentState === states.DRAFT">
      <v-empty-state :image="require('@/assets/images/create-experiment.svg')">
        <template v-slot:title>
          <h3 class="mt-2 mb-0">Start Experiment</h3>
        </template>
        <template v-slot:text>
          <v-alert border type="info" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You are now ready to start your experiment. Click the button below to start your
              experiment. </p>
          </v-alert>
        </template>
      </v-empty-state>
      <div class="text-center">
        <v-btn :loading="flags.startingExperiment" @click="startExperiment" color="primary-green" raised
          class="text-none font-weight-bold"> Start Experiment </v-btn>
      </div>
    </div>
    <v-alert border density="comfortable"  type="success" v-if="experimentState === states.ENDED && !loading"
      class="my-5" variant="tonal">
      <div v-if="isDraw" class="alert-content text-black">The experiment has ended with no clear winner. Both variants
        performed equally well.</div>
      <div class="alert-content text-black" v-else>The experiment has ended. The <strong>{{
        this.getWinnerVariantTitle}}</strong> is the
        high performer.</div>
    </v-alert>
    <div class="mt-5">
      <v-alert :closable="true" type="error" v-if="flags.errorMessage.length > 0" variant="tonal">
        <div class="alert-content">
          <strong>{{ flags.errorMessage }}</strong>
        </div>
      </v-alert>
    </div>

    <!-- Experiment detail -->
    <div class="experiment-detail mt-6" v-if="experimentState === states.RUNNING || experimentState === states.ENDED">
      <div class="competitors d-flex ga-7 justify-center mt-4">
        <div v-for="(competitor, index) in competitors" :key="competitor.id" class="competitor w-33">
          <v-card :color="competitor.champion ? 'secondary-blue' : 'tertiary-blue'" class="pt-4">
            <div class="card-tag">
              <span v-if="experimentState === states.ENDED && index === 0 && !isDraw">High Performer</span>
              <span v-if="experimentState === states.ENDED && index === 1 && !isDraw">Low Performer</span>
              <span v-if="experimentState === states.ENDED && isDraw">Equal Performers</span>
            </div>
            <v-card-title class="text-h5 text-captialize font-weight-bold">{{ variant(competitor.form_variant_id).title
              }}</v-card-title>
            <v-chip density="compact" class="font-weight-bold bg-white text-black ml-3" variant="default">{{
              competitor.champion ? 'Champion' : 'Challenger'
              }}</v-chip>
            <v-spacer class="pb-6"></v-spacer>
            <v-card-text class="bg-white">
              <v-row>
                <v-col cols="10">
                  <!-- First Column Content -->
                  <div class="first-column">
                    <p class="font-weight-bold">Views
                      <v-tooltip
                        text="No. of times this form is visited. This may include multiple visits from same visitor."><template
                          v-slot:activator="{ props }"><v-icon icon="mdi-information-variant-circle" :size="20"
                            class="cursor-pointer" color="grey" v-bind="props"></v-icon></template></v-tooltip>
                    </p>
                    <p class="font-weight-bold">Visitors
                      <v-tooltip text="No. of vistors who visited this form."><template
                          v-slot:activator="{ props }"><v-icon icon="mdi-information-variant-circle" :size="20"
                            class="cursor-pointer" color="grey" v-bind="props"></v-icon></template></v-tooltip>
                    </p>
                    <p class="font-weight-bold">Partial Entries
                      <v-tooltip text="No. of incomplete submissions."><template v-slot:activator="{ props }"><v-icon
                            icon="mdi-information-variant-circle" :size="20" class="cursor-pointer" color="grey"
                            v-bind="props"></v-icon></template></v-tooltip>
                    </p>
                    <p class="font-weight-bold">Submissions
                      <v-tooltip
                        text="No. of times form submitted. This may include duplicate submissions by the  same visitor."><template
                          v-slot:activator="{ props }"><v-icon icon="mdi-information-variant-circle" :size="20"
                            class="cursor-pointer" color="grey" v-bind="props"></v-icon></template></v-tooltip>
                    </p>
                    <p class="font-weight-bold">Conversions
                      <v-tooltip
                        text="No. of unique form submissions. Multiple submissions from same visitor will be treated as one conversion."><template
                          v-slot:activator="{ props }"><v-icon icon="mdi-information-variant-circle" :size="20"
                            class="cursor-pointer" color="grey" v-bind="props"></v-icon></template></v-tooltip>
                    </p>
                  </div>
                </v-col>
                <v-col cols="2">
                  <!-- Second Column Content -->
                  <div class="second-column">
                    <p class="font-weight-bold">{{ competitor.visit_count }} </p>
                    <p class="font-weight-bold">{{ competitor.visitor_count }}</p>
                    <p class="font-weight-bold">{{ competitor.partial_count }}</p>
                    <p class="font-weight-bold">{{ competitor.lead_count }}</p>
                    <p class="font-weight-bold">{{ competitor.conversion_count }}</p>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="mt-0">
                <v-col class="d-flex justify-center" cols="12">
                  <span class="text-h6 font-weight-bold mr-2">Conversion Rate</span>
                  <span class="text-h6 font-weight-bold ml-2">{{ (competitor.conversion_rate * 100).toFixed(2)
                    }}%</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>

    <!-- Experiment Update Modal -->
    <div class="experiment-update-modal-wrap" v-if="!loading">
      <v-dialog v-model="flags.updateExperimentModal" width="600px">
        <v-card>
          <v-toolbar color="white">
            <v-toolbar-title class="font-weight-bold"><v-icon class="mr-3" :size="25"
                icon="mdi-ab-testing"></v-icon>Update
              Experiment</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon="mdi-close" @click="flags.updateExperimentModal = false"></v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-form ref="updateExperimentForm">
              <div class="formfield">
                <v-text-field :autofocus="true" name="title" label="Title" placeholder="Enter experiment title"
                  prepend-inner-icon="mdi-text-short" v-model="experimentForm.title"
                  :rules="$valid([{ rule: 'required' }, { rule: 'between', min: '3', max: '100' }])" variant="outlined"
                  density="comfortable"></v-text-field>
              </div>
              <div class="formfield">
                <v-select label="Select your challenger variant" placeholder="" :items="challengerVariants"
                  item-title="label" prepend-inner-icon="mdi-form-dropdown" return-object v-model="challengerVariant"
                  name="challengerVariant" :rules="$valid([{ rule: 'required' }])" variant="outlined"
                  density="comfortable">
                </v-select>
              </div>
              <!-- hide until multi variant support -->
              <div v-if="false">
                <div class="formfield weight" v-for="variant in variants" :key="variant.id">
                  <div>
                    <p>{{ variant.title }} {{ champion.id === variant.id ? '(champion)' : '(variant)' }}</p>
                  </div>
                  <div>
                    <v-text-field :name="'variant[' + variant.id + ']'" label="Weight (0-100)%"
                      v-model="experimentForm.variants[variant.id]"
                      :rules="$valid([{ rule: 'required' }, { rule: 'range', min: 0, max: 100 }])" variant="outlined"
                      density="comfortable"></v-text-field>
                  </div>
                  <div>
                  </div>
                </div>
                <div class="sum-error" v-if="!validWeights">
                  <span class="error-text">The sum of all the weights must be 100%.</span>
                </div>
              </div>
              <div class="formfield">
                <v-textarea label="Note" type="textarea" rows="2" v-model="experimentForm.note"
                  placeholder="Keep note of your experiment details" variant="outlined" density="comfortable"
                  prepend-inner-icon="mdi-text-long"
                  :rules="$valid([{ rule: 'required' }, { rule: 'between', min: '3', max: '300' }])"></v-textarea>
              </div>
              <div class="formfield center-align">
                <v-btn color="primary-red" class="text-none font-weight-bold" :loading="flags.updating"
                  @click="updateExperiment">
                  Update Experiment
                </v-btn>
              </div>
            </v-form>
            <v-alert @dismiss="flags.updatedSuccess = false" class="mt-2" type="success" variant="tonal"
              v-show="flags.updatedSuccess">
              Experiment updated!
            </v-alert>
            <v-alert @dismiss="flags.updatedError = false" class="mt-2" type="error" variant="tonal"
              v-show="flags.updatedError">
              Unable to update, please try again.
            </v-alert>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '@/store/action-types'
import ExperimentState from '@/store/experiment-state'
import variantTypes from '@/store/variant-types'
import ErrorTypes from '@/store/error-types'

export default {
  data: function () {
    return {
      challengerVariant: '',
      experimentForm: {
        title: '',
        note: '',
        variants: {}
      },
      flags: {
        loadingExperiment: true,
        loadingExperimentVariants: true,
        loadingFormVariants: true,
        updating: false,
        updatedSuccess: false,
        updatedError: false,
        startingExperiment: false,
        endingExperiment: false,
        errorMessage: '',
        updateExperimentModal: false
      }
    }
  },

  mounted: function () {
    this.fetchFormExperiment()
    this.fetchFormExperimentVariants()
    this.fetchFormExperimentResult()
  },

  methods: {
    fetchFormVariants: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS, {
        formId: this.$route.params.id
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_SUCCESS, response.data.data)
        this.flags.loadingFormVariants = false
        for (let v of response.data.data) {
          if (
            v.form_variant_type.type !== variantTypes.CHAMPION &&
            parseInt(this.experimentForm.variants[v.id]) === 50
          ) {
            this.challengerVariant = { label: v.title, value: v.id }
            break
          }
        }
      }, (response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_ERROR, {
          formId: this.$route.params.id
        })
        this.flags.loadingFormVariants = false
      })
    },

    fetchFormExperiment: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENT, {
        formId: this.formId,
        experimentId: this.experimentId
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENT_SUCCESS, response.data.data)

        this.experimentForm.title = response.data.data.title
        this.experimentForm.note = response.data.data.note

        this.flags.loadingExperiment = false
      }, (response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENT_ERROR)
        this.flags.loadingExperiment = false
      })
    },

    fetchFormExperimentVariants: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENT_VARIANTS, {
        formId: this.formId,
        experimentId: this.experimentId
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENT_VARIANTS_SUCCESS, response.data.data)

        for (let v of response.data.data) {
          this.experimentForm.variants[v.form_variant_id] = v.weight
        }
        this.fetchFormVariants()

        this.flags.loadingExperimentVariants = false
      }, (response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENT_VARIANTS_ERROR)
        this.flags.loadingExperimentVariants = false
      })
    },

    fetchFormExperimentResult: function () {
      this.$store.dispatch(actionTypes.FORM_EXPERIMENT_RESULT, {
        formId: this.formId,
        experimentId: this.experimentId
      }).then((response) => {
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_RESULT_SUCCESS, response.data.data)
      }, (response) => {
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_RESULT_ERROR, [])
      })
    },

    toggleDialog() {
      this.flags.updateExperimentModal = !this.flags.updateExperimentModal
    },

    updateExperiment: async function () {
      /* this.validateForm(this.experimentFormScope).then((result) => {
      }) */
      let result = await this.$refs.updateExperimentForm.validate()
      this.setWeights()
      if (result.valid && this.validWeights) {
        this.flags.updating = true
        this.flags.updatedSuccess = false
        this.flags.updatedError = false
        this.experimentForm.formId = this.formId
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_UPDATE, {
          formId: this.formId,
          experimentId: this.experimentId,
          data: this.experimentForm
        }).then((response) => {
          this.$store.dispatch(actionTypes.FORM_EXPERIMENT_UPDATE_SUCCESS)
          this.flags.updating = false
          this.flags.updatedSuccess = true
        }, (response) => {
          this.$store.dispatch(actionTypes.FORM_EXPERIMENT_UPDATE_ERROR)
          this.flags.updating = false
          this.flags.updatedError = true
        })
      }
    },

    validateForm: function (scope) {
      return this.$validator.validateAll(scope)
    },

    startExperiment: function () {
      this.flags.startingExperiment = true
      this.flags.errorMessage = ''
      this.$store.dispatch(actionTypes.FORM_EXPERIMENT_START, {
        formId: this.formId,
        experimentId: this.experimentId
      }).then((response) => {
        this.flags.startingExperiment = false
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_START_SUCCESS, response.data.data)
          .then(() => {
            this.$router.push({ name: 'forms.experiments.overview', params: { id: this.formId } })
            // setTimeout(() => { this.$router.go() }, 10)
          })
      }, (response) => {
        this.flags.startingExperiment = false
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_START_ERROR)
        if (response.response.data.meta.error_type === ErrorTypes.ANOTHER_EXPERIMENT_RUNNING) {
          this.flags.errorMessage = 'You can\'t run two experiments at a time. Stop your already running experiment first then start this one.'
        } else if (response.response.data.meta.error_type === ErrorTypes.EXPERIMENT_ALREADY_RUNNING) {
          this.flags.errorMessage = 'Experiment is already running'
        } else if (response.response.data.meta.error_type === ErrorTypes.EXPERIMENT_ALREADY_ENDED) {
          this.flags.errorMessage = 'Experiment is already ended'
        } else {
          this.flags.errorMessage = 'Unable to start experiment, please try again.'
        }
      })
    },

    endExperiment: function () {
      this.flags.endingExperiment = true
      this.flags.errorMessage = ''
      this.$store.dispatch(actionTypes.FORM_EXPERIMENT_END, {
        formId: this.formId,
        experimentId: this.experimentId
      }).then((response) => {
        this.flags.endingExperiment = false
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_END_SUCCESS, response.data.data)
          .then(() => {
            this.fetchFormExperimentResult()
          })
      }, (response) => {
        this.flags.endingExperiment = false
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_END_ERROR)

        if (response.data.meta.error_type === ErrorTypes.EXPERIMENT_NOT_STARTED) {
          this.flags.errorMessage = 'Experiment is not started yet.'
        } else if (response.data.meta.error_type === ErrorTypes.EXPERIMENT_ALREADY_ENDED) {
          this.flags.errorMessage = 'Experiment already ended'
        } else {
          this.flags.errorMessage = 'Unable to end experiment, please try again.'
        }
      })
    },

    setWeights: function () {
      for (let variantId in this.experimentForm.variants) {
        variantId = parseInt(variantId)
        if (
          variantId === this.challengerVariant.value ||
          variantId === this.champion.id
        ) {
          this.experimentForm.variants[parseInt(this.challengerVariant.value)] = 50
        } else {
          this.experimentForm.variants[variantId] = 0
        }
      }
    },

    variant: function (id) {
      return this.$store.getters.getFormVariant(id)
    },

    resultClass: function (index) {
      if (this.isDraw) {
        return 'draw'
      }
      if (index === 0) {
        return 'winner'
      }
      return 'loser'
    }
  },

  computed: {
    states: function () {
      return ExperimentState
    },

    formId: function () {
      return parseInt(this.$route.params.id)
    },

    experimentId: function () {
      return parseInt(this.$route.params.experimentId)
    },

    loading: function () {
      return this.flags.loadingExperiment &&
        this.flags.loadingExperimentVariants &&
        this.flags.loadingFormVariants
    },

    experiment: function () {
      return this.$store.getters.formExperiment(this.experimentId) || {}
    },

    experimentState: function () {
      return this.$store.getters.formExperimentState(this.experimentId)
    },

    validWeights: function () {
      let sum = 0
      for (let index in this.experimentForm.variants) {
        sum += parseFloat(this.experimentForm.variants[index])
      }
      return sum >= 99 && sum <= 100
    },

    variants: function () {
      return this.$store.getters.getFormVariants(this.formId)
    },

    champion: function () {
      for (let v of this.variants) {
        if (v.form_variant_type.type === variantTypes.CHAMPION) {
          return v
        }
      }
      return {}
    },

    challengers: function () {
      let challengers = []
      for (let v of this.variants) {
        if (v.form_variant_type.type === variantTypes.CHAMPION) {
          continue
        }
        challengers.push(v)
      }
      return challengers
    },

    challengerVariants: function () {
      let options = []
      for (let challenger of this.challengers) {
        options.push({ label: challenger.title, value: challenger.id })
      }
      return options
    },

    experimentResult: function () {
      return this.$store.getters.getFormExperimentResult(this.experimentId)
    },

    competitors: function () {
      let competitors = this.experimentResult.competitors || []
      return competitors.sort((a, b) => b.conversion_rate - a.conversion_rate) || []
    },

    isDraw: function () {
      if (this.competitors.length < 2) {
        return false
      }
      return this.competitors[0].conversion_rate === this.competitors[1].conversion_rate
    },
    getWinnerVariantTitle: function () {
      if (this.competitors.length < 2) {
        return false
      }
      const winner = this.competitors[0].conversion_rate > this.competitors[1].conversion_rate
        ? this.competitors[0]
        : this.competitors[1];
      return this.variant(winner.form_variant_id).title;
    }
  }
}
</script>

<style lang="scss" scoped></style>
