export const CustomTheme = {
  dark: false,
  colors: {
    error: "#F44336",
    "primary-red": "#de0500",
    "secondary-red": "#F44336",
    "primary-green": "#43A047",
    "primary-blue": "#000928",
    "secondary-blue": "#0c246b",
    "tertiary-blue": "#1d49c5",
    "lightest-blue": "EDF4F9",
    "grey-border": "#BDBDBD",
    "black-border": "#000000",
    "red-border": "#DE0500",
    // Add other colors if needed
  },
};
