export default {
  'environment': 'production',
  'basePath': window.location.protocol + '//api.leadgenapp.io/api',
  'recaptchaSiteKey': '6LeV1jEqAAAAAK2j24zTO2QmBBWp5HOxyXkoYsr8',
  'pagesUrl': window.location.protocol + '//pages.leadgenapp.io',
  'formsUrl': window.location.protocol + '//forms.leadgenapp.io',
  'proofsUrl': window.location.protocol + '//proofs.leadgenapp.io',
  'scriptsUrl': window.location.protocol + '//scripts.leadgenapp.io',
  'stripePublicKey': 'pk_live_iKFWnaNNMeLUNyse6QHYLup9',
  'sampleCsvUrl': window.location.protocol + '//' + window.location.host + '/static/sample/SampleTemplate.csv',
  'paddle': {
    'vendorId': 111484,
    'trialPlanId': 638341,
    'trialScaleId': 787251,
    'trialEnterpriseId': 787252,
    'trialAnnualProId': 787253,
    'trialAnnualScaleId': 787254,
    'trialAnnualEnterpriseId': 787256,
    'starterPlanId': 889848,
    'planId': 589934,
    'scaleId': 638342,
    'enterpriseId': 768477,
    'annualProId': 740474,
    'annualScaleId': 740472,
    'annualEnterpriseId': 768479,
    'singlePlanId': 805791
  },
  'registration': {
    'recaptchaEnabled': true
  }
}
