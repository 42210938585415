<template>
  <v-container style="width: 90%;" class="recaptcha-view">
    <v-row>
      <v-col class="pa-0" cols="12">
        <h4 class="page-title">Blacklist IP</h4>
      </v-col>
      <v-col class="d-flex justify-space-between pa-0 mb-n4" cols="12">
        <v-text-field max-width="500px" v-model="search" density="compact" label="Search IPs"
          prepend-inner-icon="mdi-magnify" clearable variant="outlined" rounded></v-text-field>
        <v-btn prepend-icon="mdi-plus" color="primary-red text-none font-weight-black" rounded="md"
          class="mb-7 font-weight-bold" @click="openCreateDialog">Block IP</v-btn>
      </v-col>
      <v-data-table :headers="headers" :items="filteredBlacklistIps" @update:page="currentPage = $event"
        :page="currentPage" :items-per-page="itemsPerPage" :loading="fetching" class="border-thin rounded">
        <template width="100%" v-slot:loader>
          <v-progress-linear color="secondary-red" indeterminate height="2"></v-progress-linear>
        </template>
        <template v-slot:item.index="{ index }">
          {{ calculateIndex(index) }}
        </template>
        <template v-slot:item.ip="{ item }">
          <a class="list-item-action" @click="openEditDialog(item)">{{ item.ip }}</a>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn variant="text" icon @click="openDeleteBlackListIpModal(item)">
            <v-icon color="primary-red">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-row>

    <!-- Modals -->
    <!-- add/update model -->
    <v-dialog v-model="blacklistIpDialog" max-width="500px" rounded="sm">
      <v-card prepend-icon="mdi-account-cancel" class="form-title">
        <template v-slot:title>
          <strong>{{ isEditing ? 'Update Blocked IP' : 'Block IP' }}</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text>
          <blacklist-ip-form :edit-action="isEditing"
            :blacklistIpForm="isEditing ? selectedBlacklistIp : blacklistIpForm"
            @blacklistIpComplete="onBlacklistIpComplete" @closeDialog="closeBlacklistIpDialog" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- delete model -->
    <v-dialog v-model="deleteBlacklistIpDialog" max-width="500px">
      <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert v-if="deleteError" border type="warning" variant="tonal" density="compact">
            <p class="text-black ma-0">Unable to unblock this IP.</p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You are about to unblock this IP. Are you sure you
              want to proceed?</p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="closeModal('deleteBlacklistIpDialog')">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red" :loading="deleting"
            @click="deleteBlacklistIp">
            <span>Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import BlacklistIpForm from '@/components/BlacklistIpForm.vue'

export default {
  components: {
    BlacklistIpForm
  },
  setup() {
    const store = useStore()
    const fetching = ref(true)
    const deleteBlacklistIpDialog = ref(false)
    const blacklistIps = ref([])
    const search = ref("")
    const blacklistIpForm = ref({ ip: '', reason: '', operator: '' })
    const selectedBlacklistIp = ref(null)
    const itemsPerPage = ref(10)
    const blacklistIpDialog = ref(false)
    const isEditing = ref(false)
    const totalItems = ref(0)
    const currentPage = ref(1)
    const deleteError = ref(false)
    const deleting = ref(false)

    const headers = ref([
      { title: '#', key: 'index', sortable: false },
      { title: 'IP Address', key: 'ip', sortable: true },
      { title: 'Reason', key: 'reason', sortable: true },
      { title: 'Operator', key: 'operator', sortable: true },
      { title: 'Action', key: 'action', sortable: false }
    ])

    const options = ref({
      page: 1,
      itemsPerPage: itemsPerPage.value,
      sortBy: [],
      sortDesc: [],
    })

    const filteredBlacklistIps = computed(() => {
      if (!search.value) return blacklistIps.value
      return blacklistIps.value.filter((item) =>
        (item.ip && item.ip.toLowerCase().includes(search.value.toLowerCase())) ||
        (item.reason && item.reason.toLowerCase().includes(search.value.toLowerCase()))
      )
    })
    const calculateIndex = (index) => {
      return (currentPage.value - 1) * itemsPerPage.value + index + 1
    }

    const fetchBlacklistIps = async () => {
      fetching.value = true
      try {
        const response = await store.dispatch('blacklistips/fetchBlacklistIps')
        if (response && response.data && response.data.data) {
          blacklistIps.value = response.data.data.map(item => ({
            id: item.id,
            ip: item.ip,
            reason: item.reason,
            operator: item.operator,
          })).sort((a, b) => b.id - a.id);
          totalItems.value = response.data.total
        } else {
          blacklistIps.value = []
          totalItems.value = 0
        }
      } catch (error) {
        blacklistIps.value = []
        totalItems.value = 0
      } finally {
        fetching.value = false
      }
    }

    onMounted(fetchBlacklistIps)

    const openCreateDialog = () => {
      isEditing.value = false
      blacklistIpDialog.value = true
    }

    const openEditDialog = (item) => {
      selectedBlacklistIp.value = { ...item }
      isEditing.value = true
      blacklistIpDialog.value = true
    }

    const onBlacklistIpComplete = (success) => {
      if (success) {
        closeBlacklistIpDialog()
        fetchBlacklistIps()
      }
    }

    const openDeleteBlackListIpModal = (item) => {
      deleteBlacklistIpDialog.value = true
      selectedBlacklistIp.value = item
    }

    const deleteBlacklistIp = async () => {
      if (!selectedBlacklistIp.value) {
        deleteError.value = true
        return
      }
      deleteError.value = false
      deleting.value = true
      try {
        await store.dispatch('blacklistips/deleteBlacklistIp', selectedBlacklistIp.value)
        fetchBlacklistIps()
        deleteBlacklistIpDialog.value = false
        deleteError.value = false
        deleting.value = false
      } catch (error) {
        deleteError.value = true
        deleting.value = false
      }
    }

    const closeModal = (dialog) => {
      if (dialog === 'deleteBlacklistIpDialog') deleteBlacklistIpDialog.value = false
    }

    const closeBlacklistIpDialog = () => {
      blacklistIpDialog.value = false
      isEditing.value = false
    }

    return {
      fetching,
      deleteBlacklistIpDialog,
      blacklistIps,
      search,
      filteredBlacklistIps,
      blacklistIpForm,
      selectedBlacklistIp,
      headers,
      options,
      itemsPerPage,
      fetchBlacklistIps,
      onBlacklistIpComplete,
      openDeleteBlackListIpModal,
      deleteBlacklistIp,
      closeModal,
      blacklistIpDialog,
      isEditing,
      deleting,
      deleteError,
      openCreateDialog,
      openEditDialog,
      closeBlacklistIpDialog,
      currentPage,
      calculateIndex,
    }
  }
}
</script>
