export const registerRoutePath = {
  registerProMonthlyTrial: '/register-pro-trial',
  registerProAnnualTrial: '/register-pro-trial-annual',
  registerScaleMonthlyTrial: '/register-scale-trial',
  registerScaleAnnualTrial: '/register-scale-trial-annual',
  registerEnterpriseMonthlyTrial: '/register-enterprise-trial',
  registerEnterpriseAnnualTrial: '/register-enterprise-trial-annual',
  registerFreeTrial: '/register-free-trial',
  registerStarterMonthly: '/register-starter',
  registerProMonthly: '/register-pro',
  registerProAnnual: '/register-pro-annual',
  registerScaleMonthly: '/register-scale',
  registerScaleAnnual: '/register-scale-annual',
  registerEnterpriseMonthly: '/register-enterprise',
  registerEnterpriseAnnual: '/register-enterprise-annual',
  registerSingleAnnual: '/register-single-annual',
  registerFreeTrialFr: '/enregistrer-essai-gratuit',
  registerStarterMonthlyFr: '/registre-entree',
  registerStarterMonthlyEs: '/registro-arrancadora',
  registerStarterMonthlyDe: '/registrieren-starter',
  registerStarterMonthlyPt: '/registro-iniciante',
  registerProMonthlyFr: '/enregistrer-pro',
  registerProAnnualFr: '/enregistrer-pro-annuel',
  registerScaleMonthlyFr: '/enregistrer-scale',
  registerScaleAnnualFr: '/enregistrer-scale-annuel',
  registerEnterpriseMonthlyFr: '/enregistrer-entreprise',
  registerEnterpriseAnnualFr: '/enregistrer-entreprise-annuel',
  registerFreeTrialDt: '/registrieren-kostenlose-testversion',
  registerProMonthlyDt: '/registrieren-pro',
  registerProAnnualDt: '/registrieren-pro-jahrlich',
  registerScaleMonthlyDt: '/registrieren-scale',
  registerScaleAnnualDt: '/registrieren-scale-jahrlich',
  registerEnterpriseMonthlyDt: '/registrieren-entreprise',
  registerEnterpriseAnnualDt: '/registrieren-entreprise-jahrlich',
  registerFreeTrialPt: '/registro-teste-gratuito',
  registerProMonthlyPt: '/registro-pro',
  registerScaleMonthlyPt: '/registro-escala-mensal',
  registerEnterpriseMonthlyPt: '/registro-empresa-mensal',
  registerProAnnualPt: '/registro-pro-anual',
  registerScaleAnnualPt: '/registro-escala-anual',
  registerEnterpriseAnnualPt: '/registro-empresa-anual',
  registerFreeTrialEs: '/registrar-prueba gratuita',
  registerProMonthlyEs: '/registrar-pro',
  registerScaleMonthlyEs: '/registrar-escala-mensual',
  registerEnterpriseMonthlyEs: '/registrar-empresa-mensual',
  registerProAnnualEs: '/registrar-pro-anual',
  registerScaleAnnualEs: '/registrar-escala-anual',
  registerEnterpriseAnnualEs: '/registrar-empresa-anual'
}

export const registerRoutesName = {
  registerProMonthlyTrial: 'registerProMonthlyTrial',
  registerProAnnualTrial: 'registerProAnnualTrial',
  registerScaleMonthlyTrial: 'registerScaleMonthlyTrial',
  registerScaleAnnualTrial: 'registerScaleAnnualTrial',
  registerEnterpriseMonthlyTrial: 'registerEnterpriseMonthlyTrial',
  registerEnterpriseAnnualTrial: 'registerEnterpriseAnnualTrial',
  registerFreeTrial: 'registerFreeTrial',
  registerStarterMonthly: 'registerStarterMonthly',
  registerStarterMonthlyFr: 'registerStarterMonthlyFr',
  registerStarterMonthlyEs: 'registerStarterMonthlyEs',
  registerStarterMonthlyDe: 'registerStarterMonthlyDe',
  registerStarterMonthlyPt: 'registerStarterMonthlyPt',
  registerProMonthly: 'registerProMonthly',
  registerProAnnual: 'registerProAnnual',
  registerScaleMonthly: 'registerScaleMonthly',
  registerScaleAnnual: 'registerScaleAnnual',
  registerEnterpriseMonthly: 'registerEnterpriseMonthly',
  registerEnterpriseAnnual: 'registerEnterpriseAnnual',
  registerSingleAnnual: 'registerSingleAnnual',
  registerFreeTrialFr: 'registerFreeTrialFr',
  registerProMonthlyFr: 'registerProMonthlyFr',
  registerProAnnualFr: 'registerProAnnualFr',
  registerScaleMonthlyFr: 'registerScaleMonthlyFr',
  registerScaleAnnualFr: 'registerScaleAnnualFr',
  registerEnterpriseMonthlyFr: 'registerEnterpriseMonthlyFr',
  registerEnterpriseAnnualFr: 'registerEnterpriseAnnualFr',
  registerFreeTrialEs: 'registerFreeTrialEs',
  registerProMonthlyEs: 'registerProMonthlyEs',
  registerScaleMonthlyEs: 'registerScaleMonthlyEs',
  registerEnterpriseMonthlyEs: 'registerEnterpriseMonthlyEs',
  registerProAnnualEs: 'registerProAnnualEs',
  registerScaleAnnualEs: 'registerScaleAnnualEs',
  registerEnterpriseAnnualEs: 'registerEnterpriseAnnualEs',
  registerFreeTrialDt: 'registerFreeTrialDt',
  registerProMonthlyDt: 'registerProMonthlyDt',
  registerProAnnualDt: 'registerProAnnualDt',
  registerScaleMonthlyDt: 'registerScaleMonthlyDt',
  registerScaleAnnualDt: 'registerScaleAnnualDt',
  registerEnterpriseMonthlyDt: 'registerEnterpriseMonthlyDt',
  registerEnterpriseAnnualDt: 'registerEnterpriseAnnualDt',
  registerFreeTrialPt: 'registerFreeTrialPt',
  registerProMonthlyPt: 'registerProMonthlyPt',
  registerProAnnualPt: 'registerProAnnualPt',
  registerScaleMonthlyPt: 'registerScaleMonthlyPt',
  registerScaleAnnualPt: 'registerScaleAnnualPt',
  registerEnterpriseMonthlyPt: 'registerEnterpriseMonthlyPt',
  registerEnterpriseAnnualPt: 'registerEnterpriseAnnualPt'
}
