<template>
  <v-container style="width: 90%;" class="connections-list">
    <v-row>
      <v-col cols="12">
        <h4>Integrations</h4>
        <v-text-field hide-details class="mt-4" max-width="500px" v-model="search" density="compact"
          label="Search connections" clearable prepend-inner-icon="mdi-magnify" variant="outlined"
          rounded></v-text-field>
      </v-col>
      <v-col>
        <v-data-table class="border-thin rounded" :headers="headers" :items="filteredConnections" :loading="fetching"
          @update:page="currentPage = $event" :page="currentPage" :items-per-page="itemsPerPage" item-value="id">

          <template width="100%" v-slot:loader>
            <v-progress-linear color="secondary-red" indeterminate height="2"></v-progress-linear>
          </template>

          <template v-slot:item.index="{ index }">
            <span>{{ calculateIndex(index) }}</span>
          </template>

          <template v-slot:item.type="{ item }">
            <span class="font-weight-bold">{{ item.type }}</span>
          </template>

          <template v-slot:item.title="{ item }">
            <span>{{ item.data.title }}</span>
          </template>

          <template v-slot:item.data.created_at="{ item }">
            <span :title="item.data.created_at">
              {{ new Date(item.data.created_at).toDateString() }}
            </span>
          </template>

          <template v-slot:item.data.enable="{ item }">
            <span class="lg-badge pt-1" :class="item.data.type === 'trustedform'
              ? (item.data.active === 'Active' ? 'bg-primary-green' : 'bg-secondary-red')
              : (item.data.enable === 'Active' ? 'bg-primary-green' : 'bg-secondary-red')">
              {{ item.data.type === 'trustedform' ? item.data.active : item.data.enable }}
            </span>
          </template>

          <template v-slot:item.form_title="{ item }">
            <router-link class="text-secondary-red" :to="{ name: 'forms.overview', params: { id: item.data.form_id } }">
              {{ item.form_title }}
            </router-link>
          </template>

          <template v-slot:item.action="{ item }">
            <v-icon v-if="item.type !== formConnectionTypes.TRUSTEDFORM" class="cursor-pointer"
              @click="openDeleteFormConnectionModal(item)" color="primary-red" icon="mdi-delete-outline"></v-icon>
            <v-icon v-if="item.type !== formConnectionTypes.TRUSTEDFORM" class="cursor-pointer"
              @click="editFormConnection(item)" color="primary-red" icon="mdi-square-edit-outline"></v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Update Webhook Modal -->
    <v-dialog v-model="editWebhook" max-width="500">
      <v-form @submit.prevent="updateWebhook" ref="updateWebhook">
        <v-card prepend-icon="mdi-webhook">
          <template v-slot:title>
            <strong>Update Webhook</strong>
          </template>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              :rules="$valid([{ rule: 'required', fieldName: 'Webhook title' }, { rule: 'between', min: '3', max: '30' }])"
              v-model="webhookForm.title" density="comfortable" variant="outlined" label="Webhook Title"></v-text-field>
            <v-text-field :rules="$valid([
              { rule: 'required', fieldName: 'URL' },
              { rule: 'url', msg: 'Please enter a valid URL' }
            ])" v-model="webhookForm.url" density="comfortable" variant="outlined" label="Webhook URL"></v-text-field>
            <v-select :items="webhookMethods" v-model="webhookForm.method" density="comfortable" variant="outlined"
              label="Select Webhook Method"></v-select>
            <v-select :items="webhookFormats" v-model="webhookForm.format" density="comfortable" variant="outlined"
              label="Select Webhook Payload Format"></v-select>
            <v-switch color="primary-red" v-model="webhookForm.enable" hide-details inset label="Enable"></v-switch>
            <v-alert v-if="saveSuccess" closable density="compact" type="success" border class="mt-3">
              Webhook updated successfully.
            </v-alert>
            <v-alert v-if="saveError" closable density="compact" type="error" border class="mt-3">
              Unable to update, please try again.
            </v-alert>
          </v-card-text>
          <v-card-actions class="mb-3 mr-3">
            <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
              @click="editWebhook = false">
            </v-btn>
            <v-btn rounded="md" variant="elevated" class="font-weight-bold text-none" color="primary-red"
              :loading="saving" @click="updateWebhook">
              <span>Update</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- update Contact state Modal -->
    <v-dialog v-model="editContactState" max-width="500">
      <v-form @submit.prevent="updateContactState" ref="updateContactState">
        <v-card prepend-icon="mdi-alpha-c-circle-outline">
          <template v-slot:title>
            <strong>Update Contact State</strong>
          </template>
          <v-divider></v-divider>
          <v-card-text>
            <v-alert border type="info" variant="tonal" density="compact">
              <p class="text-black ma-0">Find the information on Secret ID directly at Contact State
                (<a href="https://www.contactstate.com/" target="_blank" class="text-info">www.contactstate.com</a>)</p>
            </v-alert>
            <v-spacer class="my-5"></v-spacer>
            <v-text-field
              :rules="$valid([{ rule: 'required', fieldName: 'Connection title' }, { rule: 'between', min: '3', max: '30' }])"
              v-model="contactState.title" density="comfortable" variant="outlined"
              label="Connection Title"></v-text-field>
            <v-text-field :rules="$valid([{ rule: 'required', fieldName: 'Landing page ID' }])"
              v-model="contactState.landingpage_id" density="comfortable" variant="outlined"
              label="landing page ID"></v-text-field>
            <v-text-field :rules="$valid([{ rule: 'required', fieldName: 'Secret Key' }])"
              v-model="contactState.secret_key" density="comfortable" variant="outlined"
              label="Secret Key"></v-text-field>
            <v-switch color="primary-red" v-model="contactState.enable" hide-details inset label="Enable"></v-switch>
            <v-alert v-if="saveSuccess" closable density="compact" type="success" border class="mt-3">
              Contact State updated successfully.
            </v-alert>
            <v-alert v-if="saveError" closable density="compact" type="error" border class="mt-3">
              Unable to update, please try again.
            </v-alert>
          </v-card-text>
          <v-card-actions class="mb-3 mr-3">
            <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
              @click="editContactState = false">
            </v-btn>
            <v-btn rounded="md" variant="elevated" class="font-weight-bold text-none" color="primary-red"
              :loading="saving" @click="updateContactState">
              <span>Update</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- Delete Integration Modal -->
    <v-dialog v-model="deleteModal" max-width="600px">
      <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert v-if="deleted && deleteError" border type="warning" variant="tonal" density="compact">
            <p class="text-black ma-0">Unable to delete form Connection.</p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You are about to delete this connection. Are you sure you
              want to proceed?</p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="deleteModal = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red" :loading="deleting"
            @click="deleteFormConnection">
            <span>Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { formConnectionTypes } from '@/shared/constants/formConnectionTypes'
export default {
  data: function () {
    return {
      headers: [
        { title: '#', key: 'index', width: '5%' },
        { title: 'Connection', key: 'type', width: '15%' },
        { title: 'Title', key: 'data.title', width: '25%' },
        { title: 'Connected', key: 'data.created_at', width: '15%', sortable: false },
        { title: 'Status', key: 'data.enable', width: '10%', sortable: false },
        { title: 'Used in Form', key: 'form_title', width: '20%' },
        { title: 'Action', key: 'action', width: '10%', sortable: false }
      ],
      currentPage: 1,
      itemsPerPage: 10,
      search: '',
      editWebhook: false,
      editContactState: false,
      deleteModal: false,
      editAction: false,
      fetching: true,
      deleting: false,
      deleteSuccess: false,
      deleted: false,
      selectedFormConnection: {},
      saveSuccess: false,
      saveError: false,
      contactStateScope: 'connection-form',
      webhookFormScope: 'webhookForm',
      saving: false,
      contactState: {
        id: '',
        title: '',
        landingpage_id: '',
        secret_key: '',
        created_at: '',
        enable: ''
      },
      webhookForm: {
        id: '',
        title: '',
        enable: '',
        method: 'POST',
        format: 'application/x-www-form-urlencoded',
        url: '',
        form_variant_id: '',
        from: 'global'
      }
    }
  },
  mounted: function () {
    this.fetchFormConnections()
  },
  methods: {
    calculateIndex: function (index) {
      return (this.currentPage - 1) * this.itemsPerPage + index + 1;
    },
    updateContactState: async function () {
      const { valid } = await this.$refs.updateContactState.validate()
      if (valid) {
        this.saveSuccess = false
        this.saveError = false
        this.saving = true
        if (this.editAction) {
          let contactState = { ...this.contactState }
          contactState.enable = contactState.enable ? 1 : 0
          this.$store.dispatch('formcontactstate/updateContactState', {
            connectionId: contactState.id,
            contactstate: contactState
          })
            .then((response) => {
              this.fetchFormConnections()
              this.saveSuccess = true
              this.saving = false
              this.$store.dispatch('formcontactstate/updateContactStateSuccess', response.data.data)
              setTimeout(() => {
                this.saveSuccess = false
              }, 4000)
            }, (response) => {
              this.saving = false
              this.saveError = true
              setTimeout(() => {
                this.saveError = false
              }, 4000)
            })
        }
      }
    },
    updateWebhook: async function () {
      const { valid } = await this.$refs.updateWebhook.validate()
      if (valid) {
        this.saveSuccess = false
        this.saveError = false
        this.saving = true
        if (this.editAction) {
          let webhook = { ...this.webhookForm }
          webhook.enable = webhook.enable ? 1 : 0
          this.$store.dispatch('formwebhook/updateFormWebhook', {
            formId: webhook.form_id,
            webhookId: webhook.id,
            webhook: webhook
          })
            .then((response) => {
              this.fetchFormConnections()
              this.saveSuccess = true
              this.saving = false
              setTimeout(() => {
                this.saveSuccess = false
              }, 4000)
            }, () => {
              this.saving = false
              this.saveError = true
              setTimeout(() => {
                this.saveError = false
              }, 4000)
            })
        }
      }
    },
    fetchFormConnections: function () {
      this.$store.dispatch('formconnection/fetchFormConnections')
        .then((response) => {
          this.$store.dispatch('formconnection/fetchFormConnectionsSuccess', response.data.data)
          this.fetching = false
        }, (response) => {
          this.fetching = false
        })
    },
    editFormConnection: function (formConnection) {
      if (formConnection.type === 'webhook') {
        this.editAction = true
        this.webhookForm.id = formConnection.data.id
        this.webhookForm.title = formConnection.data.title
        this.webhookForm.enable = formConnection.data.enable === "Active" ? true : false
        this.webhookForm.method = formConnection.data.method
        this.webhookForm.format = formConnection.data.format
        this.webhookForm.url = formConnection.data.url
        this.webhookForm.form_id = formConnection.form_id
        this.webhookForm.form_variant_id = formConnection.form_variant_id
        this.editWebhook = true
      } else if (formConnection.type === 'contactstate') {
        this.editAction = true
        this.contactState.id = formConnection.data.id
        this.contactState.title = formConnection.data.title
        this.contactState.landingpage_id = formConnection.data.landingpage_id
        this.contactState.enable = formConnection.data.enable === "Active" ? true : false
        this.contactState.form_id = formConnection.form_id
        this.contactState.secret_key = formConnection.secret_key
        this.contactState.created_at = formConnection.created_at
        this.editContactState = true
      } else if (formConnection.type === this.formConnectionTypes.TRUSTEDFORM) {
        this.editAction = true
      }
    },
    shorten: function (str, len = 10) {
      if (str.length > len) {
        return str.substring(0, len) + '...'
      }
      return str
    },
    openDeleteFormConnectionModal: function (formConnection) {
      this.selectedFormConnection = formConnection
      this.deleted = false
      this.deleteModal = true
    },
    deleteFormConnection: function () {
      this.deleteSuccess = false
      this.deleteError = false
      this.deleted = false
      this.deleting = true
      if (this.selectedFormConnection.type === 'contactstate') {
        this.$store.dispatch('formcontactstate/deleteContactState', {
          id: this.selectedFormConnection.data.id
        }).then((response) => {
          this.deleteSuccess = true
          this.deleted = true
          this.deleting = false
          this.fetchFormConnections()
          this.deleteModal = false
          this.$store.dispatch('formconnection/deleteFormConnectionSuccess', {
            id: this.selectedFormConnection.id
          })
        }, (response) => {
          this.deleteError = true
          this.deleted = true
          this.deleting = false
        })
      } else {
        this.$store.dispatch('formwebhook/deleteFormWebhook', {
          id: this.selectedFormConnection.data.id,
          formId: this.selectedFormConnection.form_id
        }).then((response) => {
          this.deleteSuccess = true
          this.deleted = true
          this.deleting = false
          this.fetchFormConnections()
          this.deleteModal = false
          this.$store.dispatch('formwebhook/deleteFormWebhookSuccess', {
            id: this.selectedFormConnection.id
          })
        }, (response) => {
          this.deleteError = true
          this.deleted = true
          this.deleting = false
        })
      }
    }
  },
  computed: {
    formConnectionTypes: function () {
      return formConnectionTypes
    },
    formConnections: function () {
      const connections = this.$store.getters['formconnection/getFormConnections'] || [];

      return connections.map((connection) => {
        const newConnection = { ...connection };

        newConnection.data.enable = connection.data.enable === 1 ? 'Active' : 'Inactive';

        if (connection.data.active !== undefined) {
          newConnection.data.active = connection.data.active === 1 ? 'Active' : 'Inactive';
        }

        return newConnection;
      });
    },
    filteredConnections: function () {
      if (!this.search) {
        return this.formConnections;
      }

      const searchTerm = this.search.toLowerCase();
      return this.formConnections.filter((connection) => {
        const title = connection.data.title.toLowerCase();
        const type = connection.type.toLowerCase();
        return title.includes(searchTerm) || type.includes(searchTerm);
      });
    },
    webhookMethods: function () {
      let methods = []
      let storeMethods = this.$store.getters['formwebhook/webhookMethods']
      for (let i in storeMethods) {
        methods.push(storeMethods[i])
      }
      return methods || []
    },
    webhookFormats: function () {
      let formats = []
      let storeFormats = this.$store.getters['formwebhook/webhookFormats']
      for (let i in storeFormats) {
        formats.push(storeFormats[i])
      }
      return formats || []
    },
    formVariants: function () {
      return this.$store.getters.getFormVariants(parseInt(this.$route.params.id))
    },
    webhookFormVariant: function () {
      for (let v of this.formVariants) {
        if (this.webhookForm.form_variant_id === v.id) {
          return v
        }
      }
      return ''
    }
  }
}
</script>
