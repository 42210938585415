<template>
  <div class="general-list-cpt">
    <div class="search-field" :class="checked.length > 0 ? 'hide' : 'show'">
      <v-text-field v-model="searchText" @input="debouncedSendData()" @click:clear="sendData()" rounded
        max-width="500px" clearable label="Search.." density="compact" prepend-inner-icon="mdi-magnify"
        variant="outlined"></v-text-field>
    </div>
    <v-toolbar v-if="checked.length > 0" dark color="white" prominent>
      <v-btn density="comfortable" icon="$close" variant="plain" @click="close()"></v-btn>
      <v-toolbar-title>{{ checked.length }} Selected</v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="checked.length > 0" class="action-buttons">
        <v-btn class="mr-3 font-weight-bold bg-primary-red text-none" prepend-icon="mdi-delete"
          @click="openDeleteModal">
          <span>Delete</span>
        </v-btn>
        <v-btn v-if="this.showDuplicate" color="white" class="bg-light-blue-lighten-2 text-none font-weight-bold"
          prepend-icon="mdi-content-copy" @click="openBulkDuplicateModal">
          <span>Duplicate</span>
        </v-btn>
      </div>
    </v-toolbar>
    <div id="general-list" class="general-list-view">
      <!-- list fields -->
      <table class="highlight">
        <thead>
          <tr>
            <th>#</th>
            <th v-if="bulkDeleteAllowed && bulkDuplicateAllowed"> <input class="checkbox" type="checkbox"
                v-model="selectAll"></th>
            <th v-for="(field, fieldIndex) in tableFields" :key="fieldIndex">
              <span v-if="field.title" v-html="field.title"></span>
              <a v-else :class="!field.sortField ? 'sortNone' : 'links'" href="#"
                @click.prevent="changeSort(field.sortField)">{{ field.name }}</a>
              <v-icon v-if="listParams.sortDirection === 'asc' && listParams.sortField === field.sortField"
                color="black" class="mr-1">mdi-arrow-up-thin</v-icon>
              <v-icon v-if="listParams.sortDirection === 'desc' && listParams.sortField === field.sortField"
                color="black" class="ml-1">mdi-arrow-down-thin</v-icon>
            </th>
          </tr>
        </thead>
        <!-- list table data -->
        <tbody>
          <tr v-if="!listData">
            <td> No Data Available</td>
          </tr>
          <tr v-else-if="!loaded">
            <td>Loading &nbsp; &nbsp; <i class="fas fa-spin fa-spinner"></i></td>
          </tr>
          <tr v-else-if="loadedError">
            <td>Whoops, looks like something went wrong. Please click refresh to try again.</td>
          </tr>
          <tr v-else v-for="(rowData, index) in listData" :key="rowData.id">
            <td> {{ (index + 1) + (listPagination.current_page - 1) * listPagination.per_page }}</td>
            <td v-if="bulkDeleteAllowed && bulkDuplicateAllowed"><input class="checkbox"
                v-if="rowData.id !== defaultThankyouId" type="checkbox" :value="rowData.id" v-model="checked"
                @change="togglePanel()" /></td>
            <template v-for="(field, fieldIndex) in tableFields" :key="fieldIndex">
              <td>
                <slot :name="field.name" :rowData="rowData"></slot>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <!-- list Pagination -->
      <div v-show="listPagination && listPagination.last_page > 1"
        class="general-list__pagination pa-3 text-end rounded">
        <ul class="pagination">
          <li :class="[{ disabled: !listPagination.prev_page_url }]"><a class="page-link" href="#"
              @click="changePage(listPagination.prev_page_url)">Prev</a></li>
          <li style="border: none;" class="page-item mx-3 font-weight-bold">Showing {{ listPagination.from }} - {{
            listPagination.to }} of {{ listPagination.total
            }}</li>
          <li :class="[{ disabled: !listPagination.next_page_url }]"><a class="page-link" href="#"
              @click="changePage(listPagination.next_page_url)">Next</a></li>
        </ul>
      </div>

      <!-- Bulk Duplicate  Modal -->
      <v-dialog v-model="isDuplicateModalOpen" max-width="fit-content">
        <v-card max-width="600" prepend-icon="mdi-content-copy">
          <template v-slot:title>
            <strong>Are you sure?</strong>
          </template>
          <v-card-text>
            <v-alert v-if="duplicateFormErrorMessage.length > 0" border type="warning" variant="tonal"
              density="compact">
              <p class="text-black ma-0"> {{ duplicateFormErrorMessage }}</p>
            </v-alert>
            <v-spacer class="my-4"></v-spacer>
            <v-alert border type="info" variant="tonal" density="compact" icon="mdi-information">
              <p class="text-black ma-0">{{ modalBodyText(actions.DUPLICATE) }}</p>
            </v-alert>
          </v-card-text>
          <v-card-actions class="mb-3 mr-4">
            <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
              @click="closeDuplicateModal">
            </v-btn>
            <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="light-blue"
              :loading="duplicatingForm" @click="bulkDuplicateForms">
              <span>Duplicate</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Bulk Delete  Modal -->
      <v-dialog v-model="isDeleteModalOpen" max-width="fit-content">
        <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
          <template v-slot:title>
            <strong>Are you sure?</strong>
          </template>
          <v-card-text>
            <v-alert v-if="deleteFormErrorMessage.length > 0" border type="warning" variant="tonal" density="compact">
              <p class="text-black ma-0"> {{ deleteFormErrorMessage }}</p>
            </v-alert>
            <v-spacer class="my-4"></v-spacer>
            <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
              <p class="text-black ma-0">{{ modalBodyText(actions.DELETE) }}</p>
            </v-alert>
          </v-card-text>
          <v-card-actions class="mb-3 mr-4">
            <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
              @click="closeDeleteModal">
            </v-btn>
            <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red"
              :loading="deletingForms" @click="deleteForms">
              <span>Delete</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- success messages -->
      <v-snackbar rounded="md" height="50" v-model="snackbar.visible" :timeout="snackbar.duration" color="success">
        <p class=" text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.message }} </p>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import helperMixin from '@/mixins/helper'
import { mapGetters } from 'vuex'
import userMixin from '@/mixins/user'
import { debounce } from 'lodash'
import { resourceTypes } from '@/shared/constants/generalList/list'
import { actions } from '@/shared/constants/generalList/actions'

export default {
  mixins: [helperMixin, userMixin],
  props: {
    fields: {
      type: Array
    },
    listData: {
      type: Array
    },
    defaultThankyouId: {
      type: Number
    },
    listPagination: {
      type: Object
    },
    loaded: {
      type: Boolean
    },
    loadedError: {
      type: Boolean
    },
    bulkDeleteAllowed: {
      type: Boolean
    },
    bulkDuplicateAllowed: {
      type: Boolean
    }
  },
  data: function () {
    return {
      isDuplicateModalOpen: false,
      isDeleteModalOpen: false,
      snackbar: {
        visible: false,
        message: '',
        duration: 5000
      },
      duplicatedForm: null,
      duplicatingForm: false,
      duplicateFormErrorMessage: '',
      checked: [],
      deleteFormErrorMessage: '',
      deletingForms: false,
      searchText: '',
      currentPage: null,
      tableFields: [],
      listParams: {
        page: 1,
        search: {},
        sortDirection: 'desc',
        sortField: 'created_at',
        formId: this.$route.params.id || null,
        variantId: this.$route.params.variantId
      }
    }
  },
  mounted: function () {
    this.debouncedSendData = debounce(this.sendData, 2000)
    this.sendData()
    this.normalizeFields()
  },
  unmounted: function () {
    this.debouncedSendData.cancel()
  },
  methods: {
    normalizeFields() {
      if (!this.fields) {
        this.warn('You need to provide "fields" prop.')
        return
      }
      this.tableFields = []
      let self = this
      let obj
      this.fields.forEach(function (field, i) {
        if (typeof (field) === 'string') {
          obj = {
            name: field
          }
        } else {
          obj = {
            name: field.name,
            title: field.title,
            sortField: field.sortField
          }
        }
        self.tableFields.push(obj)
      })
    },
    close: function () {
      this.checked = []
      this.$emit('togglePanel', false)
    },
    changeSort: function (field) {
      if (!field) {
        return
      }
      if (this.listParams.sortField === field) {
        this.listParams.sortDirection = this.listParams.sortDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.listParams.sortField = field
      }
      this.listParams.page = 1
      this.sendData()
    },
    sendData: function () {
      this.fields.forEach((field, index) => {
        if (field.searchField) {
          this.listParams.search[field.searchField] = this.searchText
        }
      })
      if (this.searchText && this.listParams.page === this.listPagination.current_page) {
        this.listParams.page = 1
      } else if (!this.searchText && this.listParams.page === this.listPagination.current_page) {
        this.listParams.page = 1
      }
      this.$emit('onListParamsChange', this.listParams)
    },
    togglePanel: function () {
      if (this.checked.length > 0) {
        this.$emit('togglePanel', true)
      } else {
        this.$emit('togglePanel', false)
      }
    },
    changePage: function (page) {
      if (!page) return
      let pageNo = page?.split('page=')[1]
      this.currentPage = parseInt(pageNo)
      this.listParams.page = this.currentPage
      this.sendData()
    },
    bulkDuplicateForms: function () {
      if (!this.bulkDuplicateForms) {
        return
      }
      this.duplicatingForm = true
      this.$store.dispatch(this.dispatchMessage(this.actions.DUPLICATE), { checked: this.checked, userId: this.user.id })
        .then((response) => {
          this.duplicatingForm = false
          this.$store.dispatch(this.dispatchMessageSuccess(this.actions.DUPLICATE), [])
          this.closeDuplicateModal()
          let snackMessage = this.snackMessageText(this.actions.DUPLICATE)
          this.showSnackBar(snackMessage)
          this.listParams.page = 1
          this.sendData()
        }, (response) => {
          this.duplicatingForm = false
          this.$store.dispatch('formlists/bulkDuplicateFormsError', null)
          this.duplicateFormErrorMessage = 'Unable to duplicate form, please try again.'
        })
    },
    deleteForms: function () {
      this.deletingForms = true
      this.$store.dispatch(this.dispatchMessage(this.actions.DELETE), { checked: this.checked, userId: this.user.id })
        .then((response) => {
          this.deletingForms = false
          this.$store.dispatch(this.dispatchMessageSuccess(this.actions.DELETE), response.data.data)
          this.closeDeleteModal()
          let snackMessage = this.snackMessageText(this.actions.DELETE)
          this.showSnackBar(snackMessage)
          this.listParams.page = 1
          this.sendData()
        }, (response) => {
          this.deletingForms = false
          this.$store.dispatch('formlists/bulkDeleteFormsError', [])
          this.deleteFormErrorMessage = 'Unable to delete form, please try again.'
        })
    },
    dispatchMessage: function (action) {
      const resource = this.listParams && this.listParams.resource
      const messages = {
        [this.actions.DELETE]: {
          [resourceTypes.FORM_ENDINGS]: 'formendings/bulkDeleteFormEndings',
          [resourceTypes.FORMS_LIST]: 'formlists/bulkDeleteForms'
        },
        [this.actions.DUPLICATE]: {
          [resourceTypes.FORM_ENDINGS]: 'formendings/bulkDuplicateFormEndings',
          [resourceTypes.FORMS_LIST]: 'formlists/bulkDuplicateForms'
        }
      }
      return (messages[action] && messages[action][resource]) || ''
    },
    dispatchMessageSuccess: function (action) {
      const resource = this.listParams && this.listParams.resource
      const messages = {
        [this.actions.DELETE]: {
          [resourceTypes.FORM_ENDINGS]: 'formendings/bulkDeleteFormEndingsSuccess',
          [resourceTypes.FORMS_LIST]: 'formlists/bulkDeleteFormsSuccess'
        },
        [this.actions.DUPLICATE]: {
          [resourceTypes.FORM_ENDINGS]: 'formendings/bulkDuplicateFormEndingsSuccess',
          [resourceTypes.FORMS_LIST]: 'formlists/bulkDuplicateFormsSuccess'
        }
      }
      return (messages[action] && messages[action][resource]) || ''
    },
    snackMessageText: function (action) {
      const resource = this.listParams && this.listParams.resource
      const messages = {
        [this.actions.DELETE]: {
          [resourceTypes.FORM_ENDINGS]: 'Form Endings deleted successfully.',
          [resourceTypes.FORMS_LIST]: 'Forms deleted successfully.'
        },
        [this.actions.DUPLICATE]: {
          [resourceTypes.FORM_ENDINGS]: 'Form Endings duplicated successfully.',
          [resourceTypes.FORMS_LIST]: 'Forms duplicated successfully.'
        }
      }
      return (messages[action] && messages[action][resource]) || ''
    },
    openBulkDuplicateModal() {
      this.isDuplicateModalOpen = true;
    },
    closeDuplicateModal() {
      this.isDuplicateModalOpen = false;
      this.close()
    },
    openDeleteModal: function () {
      this.isDeleteModalOpen = true
    },
    closeDeleteModal: function () {
      this.isDeleteModalOpen = false
      this.close()
    },
    showSnackBar(message) {
      this.snackbar.message = message;
      this.snackbar.visible = true;
    },
    reload: function () {
      return this.sendData()
    },
    modalHeaderText: function (action) {
      const resource = this.listParams && this.listParams.resource
      const messages = {
        [this.actions.DELETE]: {
          [resourceTypes.FORM_ENDINGS]: 'Delete Form Ending',
          [resourceTypes.FORMS_LIST]: 'Delete Form'
        },
        [this.actions.DUPLICATE]: {
          [resourceTypes.FORM_ENDINGS]: 'Duplicate Form Ending',
          [resourceTypes.FORMS_LIST]: 'Duplicate Form'
        }
      }
      return (messages[action] && messages[action][resource]) || ''
    },
    modalBodyText: function (action) {
      const resource = this.listParams && this.listParams.resource
      const messages = {
        [this.actions.DELETE]: {
          [resourceTypes.FORM_ENDINGS]: 'Proceed with caution. You are about to delete the selected form endings.',
          [resourceTypes.FORMS_LIST]: 'Proceed with caution. You are about to delete the selected forms.'
        },
        [this.actions.DUPLICATE]: {
          [resourceTypes.FORM_ENDINGS]: 'You are about to duplicate the selected form endings. Do you want to proceed?',
          [resourceTypes.FORMS_LIST]: 'You are about to duplicate the selected forms. Do you want to proceed?'
        }
      }
      return (messages[action] && messages[action][resource]) || ''
    }
  },
  computed: {
    ...mapGetters([
      'forms',
      'token'
    ]),
    actions: function () {
      return actions
    },
    showDuplicate: function () {
      if (this.isAdmin || this.isSuperCustomer) {
        return true
      }
      return this.plan.form_limit > this.listPagination.total
    },
    selectAll: {
      get: function () {
        if (this.listParams.resource === resourceTypes.FORM_ENDINGS) {
          const allExceptExcluded = this.listData.filter(form => form.id !== this.defaultThankyouId)
          return this.listData ? this.checked.length === allExceptExcluded.length : false
        } else {
          return this.listData ? this.checked.length === this.listData.length : false
        }
      },
      set: function (value) {
        let checked = []
        if (value) {
          if (!this.listData) {
            return
          }
          this.listData.forEach(form => {
            if (form.id !== this.defaultThankyouId) {
              checked.push(form.id)
            }
          })
        }
        if (checked.length > 0) {
          this.$emit('togglePanel', true)
        } else {
          this.$emit('togglePanel', false)
        }
        this.checked = checked
      }
    }
  }
}
</script>
