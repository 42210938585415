<template>
  <div class="form-creator-view">
    <!-- HEADER -->
    <div class="form-creator-view__header">
      <!-- BACK -->
      <div class="form-creator-view__header__back">
        <router-link :to="{
          name: 'forms.overview',
          params: { id: $route.params.id || 0 },
        }" v-if="this.$route.params.id && this.$route.params.variantId">
          <i class="material-icons">arrow_back</i>
        </router-link>
        <router-link :to="{ name: 'forms.create.option' }" v-else>
          <v-icon>mdi-arrow-left</v-icon>
        </router-link>
      </div>
      <!-- VIEWS -->
      <div class="form-creator-view__header__views">
        <div :class="{ activeBackground: isBuildTab }">
          <div class="inner-Links" @click="switchTab(tabs.BUILD)">
            <div class="logo" :class="{ activeLogo: isBuildTab }">
              <img class="logo--img" src="./../assets/images/icons/build.svg" alt="LeadGen" />
              <img class="logo--image" src="./../assets/images/icons/build-active.svg" alt="LeadGen" />
            </div>
            <div :class="{ activeOne: isBuildTab }">Build</div>
          </div>
        </div>
        <div :class="{ activeBackground: isDesignTab }">
          <div class="inner-Links" @click="switchTab(tabs.DESIGN)" :class="{
            disableOne:
              !this.$route.params.id || !this.$route.params.variantId,
          }">
            <div class="logo" :class="{ activeLogo: isDesignTab }">
              <img class="logo--img" src="./../assets/images/icons/settings.svg" alt="LeadGen" />
              <img class="logo--image" src="./../assets/images/icons/settings-active.svg" alt="LeadGen" />
            </div>
            <div :class="{ activeOne: isDesignTab }">Design</div>
          </div>
        </div>
        <div :class="{ activeBackground: isEmbedTab }">
          <div class="inner-Links" @click="switchTab(tabs.EMBED)" :class="{
            disableOne:
              !this.$route.params.id || !this.$route.params.variantId,
          }">
            <div class="logo" :class="{ activeLogo: isEmbedTab }">
              <img class="logo--img" src="./../assets/images/icons/share.svg" alt="LeadGen" />
              <img class="logo--image" src="./../assets/images/icons/share-active.svg" alt="LeadGen" />
            </div>
            <div :class="{ activeOne: isEmbedTab }">Share</div>
          </div>
        </div>
        <div :class="{ activeBackground: isFormEndingsTab }">
          <div class="inner-Links" @click="switchTab(tabs.FORMENDINGS)" :class="{
            disableOne:
              !this.$route.params.id || !this.$route.params.variantId,
          }">
            <div class="logo" :class="{ activeLogo: isFormEndingsTab }">
              <img style="height: 24px" class="logo--img" src="./../assets/images/icons/form-endings.svg"
                alt="LeadGen" />
              <img style="height: 24px" class="logo--image" src="./../assets/images/icons/form-endings-active.svg"
                alt="LeadGen" />
            </div>
            <div :class="{ activeOne: isFormEndingsTab }">Form Endings</div>
          </div>
        </div>
      </div>
      <!-- ACTIONS -->
      <div class="d-flex justify-end gc-3">
        <v-tooltip :text="submitBtnText" location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn v-if="!isFormEndingsTab" :loading="creating" @click="() => validateForm()" color="white"
              variant="flat" rounded="md" size="large" class="save-btn" v-bind="props">
              <v-icon color="secondary-red">mdi-content-save-outline</v-icon>
              <span class="text-none text-secondary-red">{{ submitBtnText }}</span>
            </v-btn>
          </template>
        </v-tooltip>

        <v-tooltip text="Preview" location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn :disabled="!isUpdateForm()" @click="previewForm()" color="white" variant="outlined" rounded="md"
              size="large" class="preview-btn" v-bind="props">
              <v-icon color="white">mdi-eye</v-icon>
              <span class="text-none text-white">Preview</span>
            </v-btn>
          </template>
        </v-tooltip>
      </div>
    </div>
    <!-- BODY -->
    <div class="form-creator-view__body">
      <v-snackbar rounded="md" height="50" v-model="snackbar.show" :color="snackbar.color" :timeout="5000">
        <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
      </v-snackbar>

      <div @scroll.native="onScroll" >
      <div v-if="isBuildTab">
        <div class="container">
          <v-form id="form-builder" ref="formBuilderForm" @submit.prevent="() => false">
            <div class="builder-header">
              <div class="row">
                <div class="col s12 m12">
                  <div class="input-field col m5 offset-m4 s12">
                    <v-text-field id="title" type="text" name="title" variant="outlined" label="Form Title"
                      density="comfortable" v-model="formTitle" :rules="$valid([{ rule: 'required' }])"></v-text-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="builder-body">
              <form-steps></form-steps>

              <!-- FOOTER TEXT -->
              <v-expansion-panels elevation="0" class="py-2 px-0" ripple>
                <v-expansion-panel>
                  <v-expansion-panel-title color="blue-lighten-5">
                    <h6 class="d-flex justify-left mb-0">
                      Footer Text
                    </h6>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="border-thin border-t-0">
                    <div class="footer-text">
                      <quill-editor id="footer_text" name="footer_text" v-model:content="footer.text" contentType="html"
                        :options="footer.option">
                      </quill-editor>
                      <v-switch color="secondary-red" class="d-flex justify-end pa-2" id="all_steps" name="all_steps"
                        title="Enable Footer for All Steps in Multi-Step Forms" hide-details
                        :disabled="!(steps.length > 1)" v-model="footer.allStepsFooter" label="Enable on all steps">
                      </v-switch>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- HIDDEN FIELDS -->
              <v-expansion-panels elevation="0" class="py-2 px-0" ripple>
                <v-expansion-panel>
                  <v-expansion-panel-title color="blue-lighten-5">
                    <h6 class="d-flex justify-left mb-0">
                      Hidden Fields
                    </h6>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="border-thin border-t-0">
                    <div v-for="hiddenField in hiddenFields" :key="hiddenField.id">
                      <v-row class="my-0 py-0 mb-n7">
                        <v-col>
                          <v-text-field density="comfortable" variant="outlined" label="Field Name"
                            placeholder="Set hidden field name" v-model="hiddenField.name"
                            :name="`hidden-field${hiddenField.id}`"
                            @update:modelValue="updateHiddenFieldAttribute($event, hiddenField, 'name')" :rules="$valid([
                              { rule: 'required' },
                              { rule: 'alphaNumDashSpace' },
                              {
                                rule: 'expression',
                                value: !isDuplicateHiddenFieldName(hiddenField),
                                msg: 'Duplicate field name is not allowed. Questions, Hidden fields & Choice formula field name should be unique.'
                              }])"></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field density="comfortable" variant="outlined" label="Field Default Value"
                            placeholder="Set hidden field value" v-model="hiddenField.default_value"
                            @update:modelValue="updateHiddenFieldAttribute($event, hiddenField, 'default_value')"></v-text-field>
                        </v-col>
                        <v-col>
                          <v-switch :model-value="Boolean(hiddenField.capture_from_url_parameter)"
                            @update:modelValue="updateHiddenFieldAttribute($event, hiddenField, 'capture_from_url_parameter')"
                            label="Capture From Url Parameter" hide-details color="secondary-red"></v-switch>
                        </v-col>
                        <v-col cols="1">
                          <v-icon class="cursor-pointer mt-5" color="secondary-red" title="Delete Hidden Field"
                            @click="deleteHiddenField(hiddenField.id)">mdi-delete</v-icon>
                        </v-col>
                      </v-row>
                    </div>
                    <v-btn rounded="md" variant="flat" color="light-blue" prepend-icon="mdi-plus"
                      class="text-none ml-1 mt-2 font-weight-bold" @click="addHiddenField()">Add Field
                    </v-btn>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- CHOICE FORMULA -->
              <v-expansion-panels elevation="0" class="py-2 px-0" ripple v-model="choicePanel"
                v-if="choiceFormulaTypeQuestions && choiceFormulaTypeQuestions.length > 0">
                <v-expansion-panel>
                  <v-expansion-panel-title color="blue-lighten-5" height="10">
                    <h6 class="d-flex justify-left ma-0 pa-0">Embed Choice Formula
                    </h6>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="border-thin border-t-0">
                    <v-text-field active class="mt-4" density="comfortable" variant="outlined" label="Choice Formula"
                      :placeholder="'e.g. ' + choiceFormulaTypeQuestions.join('+')" v-model="choiceFormula"
                      name="choice-formula-value"
                      :hint="'Available fields are ' + choiceFormulaTypeQuestions.join(', ')" :rules="$valid([
                        {
                          rule: 'expression',
                          value: validateChoiceFormula().length === 0,
                          msg: 'Invalid choice formula. Please add valid fields.'
                        }])"></v-text-field>

                    <v-text-field class="mt-n2" density="comfortable" variant="outlined"
                      label="Field Name(used for integrations e.g. webhooks)" placeholder="Set field name"
                      v-model="calculator.fieldName" @update:modelValue="updateFormCalculator($event, 'fieldName')"
                      name="choice-formula-name" :rules="$valid([
                        {
                          rule: 'expression',
                          value: !(validateCalculatorFieldName && (validateCalculatorFieldName.gdpr || validateCalculatorFieldName.question)),
                          msg: 'Duplicate field name is not allowed. Questions, Hidden fields & Choice formula field name should be unique.'
                        }])"></v-text-field>

                    <v-alert class="mt-n4" type="info" variant="tonal" rounded="md" density="compact">
                      <p class="text-black mb-0">
                        Discover the step-by-step process to create a Calculator Form with LeadGen.
                        <a href="https://LeadGenapp.io/help/how-to-build-a-calculator-form/"
                          class="text-none pl-0 mt-0 pt-0 link text-info" target="_blank">
                          Learn More
                          <v-icon small>mdi-open-in-new</v-icon>
                        </a>
                      </p>
                    </v-alert>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-form>
        </div>
      </div>

      <div v-if="isDesignTab && isDataLoaded">
        <div class="">
          <theme-settings> </theme-settings>
        </div>
      </div>

      <div v-if="isEmbedTab">
        <div class="container">
          <v-form ref="formBuilderShareForm" @submit.prevent="() => false">
            <share-form> </share-form>
          </v-form>
        </div>
      </div>

      <div v-if="isFormEndingsTab">
        <v-container class="pt-12">
          <form-endings-list> </form-endings-list>
        </v-container>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-eval */
import * as actionTypes from '../store/action-types'
import FormSteps from './FormSteps'
import questionTypesMap from '@/store/question-types-map'
import { rangeSkinIds } from '../shared/constants/formQuestionSkins'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { mapGetters } from 'vuex'
import FormVariantTheme from '@/components/FormVariantTheme/FormVariantTheme'
import shareForm from './FormEmbed'
import _ from 'lodash'
import config from '@/api/config'
import authMixin from '@/mixins/auth'
import formBuilderTabs from '@/shared/constants/formBuilderTabs.js'
import FormEndingsList from '../views/FormEndingsList.vue'

let toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['link'],
]

export default {
  props: {
    action: {
      type: String,
      default: 'create',
    },
    formId: {
      type: Number,
      default: null,
      required: false,
    },
    tab: {
      type: String,
      default: formBuilderTabs.BUILD,
      required: false,
    },
  },

  mixins: [authMixin],

  components: {
    'form-steps': FormSteps,
    'quill-editor': QuillEditor,
    'theme-settings': FormVariantTheme,
    'share-form': shareForm,
    FormEndingsList,
  },

  data() {
    return {
      formScope: 'form_builder',
      creating: false,
      errorMessage: '',
      saved: false,
      // showHiddenFields: false,
      // showChoiceFormula: false,
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
        },
      },
      footer: {
        text: "",
        // show: false,
        allStepsFooter: false,
        option: {
          modules: {
            imageResize: true,
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ size: ["small", "large", "huge"] }],
              ["bold", "italic", "underline", "strike"],
              [{ color: [] }, { background: [] }],
              [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
              ["link", "image"],
              ["clean"],
            ],
          },
          placeholder: "Type here... Make changes by selecting text.",
        },
      },
      buildToggle: true,
      designToggle: false,
      shareToggle: false,
      fetchHiddenFieldsComplete: false,
      getThemeComplete: false,
      getThemesTemplateComplete: false,
      tabs: formBuilderTabs,
      choicePanel: [0],
      snackbar: {
        text: null,
        show: false,
        color: null
      }
    }
  },

  mounted: function () {
    this.loadCredentials()
    if (this.isUpdateForm()) {
      this.fetchHiddenFields()
      this.getTheme()
      this.getThemesTemplate()
    } else {
      this.$store.dispatch(actionTypes.FORMBUILDER_SET_DEFAULT_THEME, {
        id: -1,
      })
    }

    this.loadFooterText()
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    scrollToBottom: () => {
      const body = document.body
      const html = document.documentElement
      const height = Math.max(
        body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight
      )
      window.scrollTo({
        top: height,
        behavior: 'smooth'
      })
    },
    loadFooterText: function () {
      this.$store
        .dispatch(actionTypes.FORM_FETCH_SETTING, {
          formId: this.formID,
        })
        .then(
          (response) => {
            this.footer.text = response?.data?.data?.footer_text
            this.footer.allStepsFooter = response?.data?.data?.all_steps_footer
          },
          () => {
            this.footer.text = ""
            this.footer.allStepsFooter = false
          }
        )
    },
    loadCredentials: function () {
      this.$store.dispatch("setting/fetchCredentials").then((response) => {
        this.settingsLoaded = true
        this.$store.dispatch(
          "setting/fetchCredentialsSuccess",
          response?.data?.data
        )
      })
    },
    showErrorSnackbar: function () {
      if (this.saved && this.errorMessage.length === 0) {
        this.$refs.successMessageSnackbar.createSnackbar({
          message: "Your form has been updated successfully.",
          duration: 100 * 50,
        })
      } else {
        this.$refs.errorMessageSnackbar.createSnackbar({
          message: this.errorMessage,
          duration: 100 * 50,
        })
      }
    },
    switchTab: function (tab) {
      if (!this.$route.params.id || !this.$route.params.variantId) {
        return
      }

      this.$router.push({
        name: "forms.variants.edit",
        params: {
          id: this.$route.params.id,
          variantId: this.$route.params.variantId,
          tab: tab,
        },
      })
    },
    previewForm: function () {
      const formId = this.$route.params.id
      const variantId = this.$route.params.variantId
      const token = this.authMixin_token
      const previewLink = `${config.formsUrl}/preview/forms/${formId}/variants/${variantId}?token=${token}`

      window.open(previewLink, "_blank")
    },
    getTheme: function () {
      if (this.$route.params.id && this.$route.params.variantId) {
        this.$store
          .dispatch(actionTypes.FORMBUILDER_GET_THEME_SETTING, {
            formId: this.$store.state.formBuildState.form_id,
            variantId: this.$store.state.formBuildState.id,
          })
          .then((response) => {
            this.$store
              .dispatch(
                actionTypes.FORMBUILDER_GET_THEME_SETTING_SUCCESS,
                response.data.data
              )
              .then(() => {
                this.getThemeComplete = true
              })
          })
          .catch((error) => {
            console.error("error: ", error)
          })
      }
    },
    getThemesTemplate: function () {
      this.$store
        .dispatch(actionTypes.FORMBUILDER_GET_FORM_THEME_TEMPLATES)
        .then((response) => {
          this.$store
            .dispatch(
              actionTypes.FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS,
              response.data.data
            )
            .then(() => {
              this.getThemesTemplateComplete = true
            })
        })
        .catch((error) => {
          console.error("error: ", error)
        })
    },
    validateForm: async function () {
      // Normal validation process
      this.errorMessage = ''

      if (this.$refs.formBuilderForm) {
        // validate build tab
        const { valid } = await this.$refs.formBuilderForm.validate()
        if (!valid) {
          this.errorMessage = 'Please make sure all required fields are filled out correctly'
          this.showSnackbar(this.errorMessage, true)
          return // Prevent submission if the form is not valid
        }
      } else if (this.$refs.formBuilderShareForm) {
        // validate share tab
        const { valid } = await this.$refs.formBuilderShareForm.validate()
        if (!valid) {
          this.errorMessage = 'Please make sure all required fields are filled out correctly'
          this.showSnackbar(this.errorMessage, true)
          return // Prevent submission if the form is not valid
        }
      } 
      if (this.validateChoiceFormula().length > 0) {
        this.errorMessage = 'Choice Formula is incorrect, Please use only available fields and operators.'
        this.scrollToBottom()
        this.showSnackbar(this.errorMessage, true)
        return // Prevent submission if the form is not valid
      }
      this.submitForm() // Submit if validation is passed or skipped
    },

    submitForm: function () {
      let self = this
      self.errorMessage = ''
      if (this.action === 'create') {
        this.updateBuildChanges()
      } else if (this.isDesignTab || this.isBuildTab || this.isEmbedTab) {
        this.updateBuildChanges()
        this.updateThemeChanges()
        this.updateEmbedChanges()
      }
    },
    updateBuildChanges: function () {
      this.errorMessage = ''

      if (this.choiceFormulaTypeQuestions.length === 0) {
        this.choiceFormula = ""
      }

      this.errorMessage = ''
      this.creating = true
      this.saved = false
      if (this.action === "create") {
        if (!this.formId) {
          // create form with variant first time
          this.$store.dispatch(actionTypes.FORMBUILDER_CREATE_FORM).then(
            (response) => {
              this.saveHiddenFields(
                parseInt(response.data.data.id),
                parseInt(response.data.data.variants[0].id)
              )
              this.creating = false
              this.saved = true
              this.$store
                .dispatch(actionTypes.FORMBUILDER_UPDATE_ID, {
                  id: response.data.data.id,
                })
                .then(() => {
                  this.saveFooterText(
                    this.footer.text,
                    this.footer.allStepsFooter,
                    response.data.data.id
                  )
                  this.$router.push({
                    name: "forms.variants.edit",
                    params: {
                      id: response.data.data.id,
                      variantId: response.data.data.variants[0].id,
                    },
                  })
                  this.$store.dispatch(
                    actionTypes.FORMBUILDER_SAVE_THEME_SETTING,
                    {
                      formId: response.data.data.id,
                      variantId: response.data.data.variants[0].id,
                    }
                  )
                })
            },
            () => {
              this.creating = false
              this.saved = true
              this.errorMessage = "Unable to create form, Please try again."
              //this.showErrorSnackbar()
              this.showSnackbar(this.errorMessage, true)
            }
          )
        } else {
          // create varaint
          this.$store
            .dispatch(actionTypes.FORMBUILDER_CREATE_FORM_VARIANT, {
              formId: this.formId,
            })
            .then(
              (response) => {
                let variant = response.data.data
                // create variant hidden fields
                this.saveHiddenFields(
                  parseInt(this.formId),
                  parseInt(variant.id)
                )
                this.creating = false
                this.saved = true
                this.$store
                  .dispatch(actionTypes.FORMBUILDER_CREATE_FORM_VARIANT_SUCCESS)
                  .then(() => {
                    // create variant theme
                    this.$router.push({
                      name: "forms.variants.edit",
                      params: { id: this.formId, variantId: variant.id },
                    })
                    this.$store.dispatch(
                      actionTypes.FORMBUILDER_SAVE_THEME_SETTING,
                      {
                        formId: this.formId,
                        variantId: variant.id,
                      }
                    )
                  })
              },
              () => {
                this.creating = false
                this.saved = true
                this.errorMessage = "Unable to update form,  Please try again."
                //this.showErrorSnackbar()
                this.showSnackbar(this.errorMessage, true)
                this.$store.dispatch(
                  actionTypes.FORMBUILDER_CREATE_FORM_VARIANT_ERROR
                )
              }
            )
        }
      } else if (this.action === "update") {
        // update variant
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_FORM).then(
          (response) => {
            this.saveHiddenFields(this.formID, this.variantId)
            this.saveFooterText(
              this.footer.text,
              this.footer.allStepsFooter,
              this.formID
            )
            this.$store.dispatch(actionTypes.FORMBUILDER_RESET, {
              state: response.data.data,
            })

            this.creating = false
            this.saved = true
            //this.showErrorSnackbar()
            this.showSnackbar()
          },
          () => {
            this.creating = false
            this.errorMessage = "Unable to update form, Please try again."
            //this.showErrorSnackbar()
            this.showSnackbar(this.errorMessage, true)
            this.saved = true
          }
        )
      }
    },
    updateThemeChanges: function () {
      let self = this
      self.creating = true
      self.saved = false
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_THEME_SETTING, {
        formId: this.$store.state.formBuildState.form_id,
        variantId: this.$store.state.formBuildState.id
      }).then((err) => {
        self.creating = false
        self.saved = true
        //this.showErrorSnackbar()
        this.showSnackbar(this.errorMessage, true)
      })
    },

    updateEmbedChanges: function () {
      let self = this
      self.creating = true
      self.saved = false
      let { height, width, formShadow, fullWidthForm } = this.$store.state.formPreview
      const formId = this.$store.state.formBuildState.form_id
      this.$store.dispatch('FORM_UPDATE_IFRAME_DIMENSIONS', {
        formId: formId,
        variantId: this.variantId,
        width: width,
        height: height,
        formShadow: formShadow,
        fullWidthForm: fullWidthForm
      }).then((err) => {
        self.creating = false
        self.saved = true
        //this.showErrorSnackbar()
        this.showSnackbar(this.errorMessage, true)
      })
    },

    goToPreview: function () {
      this.$router.push({
        name: "forms.variants.preview",
        params: {
          id: this.$route.params.id,
          variantId: this.$route.params.variantId,
        },
      })
    },

    isUpdateForm: function () {
      return this.action === "update"
    },

    validateChoiceFormula: function () {
      let choiceFormula = this.choiceFormula

      if (!this.choiceFormula || this.choiceFormulaTypeQuestions.length === 0) {
        return ""
      }

      for (let c of this.choiceFormulaTypeQuestions) {
        choiceFormula = choiceFormula.replace(new RegExp(c, "g"), 1)
      }

      try {
        let v = eval(choiceFormula)

        if (isNaN(v)) {
          return "Formula is incorrect, Please use only available fields and operators."
        }

        return ""
      } catch (e) {
        return "Formula is incorrect, Please use only available fields and operators."
      }
    },

    fetchHiddenFields: function () {
      this.$store
        .dispatch(actionTypes.RESET_FORM_HIDDEN_FIELDS, [])
        .then(() => {
          this.$store
            .dispatch(actionTypes.FETCH_FORM_HIDDEN_FIELDS, {
              formId: this.formID,
              variantId: this.variantId,
            })
            .then((response) => {
              this.$store
                .dispatch(
                  actionTypes.FETCH_FORM_HIDDEN_FIELDS_SUCCESS,
                  response.data.data
                )
                .then(() => {
                  this.fetchHiddenFieldsComplete = true
                })
            })
        })
    },

    addHiddenField: function () {
      this.$store.dispatch(actionTypes.UPDATE_FORM_HIDDEN_FIELDS_SUCCESS, {
        hiddenFields: [
          {
            id: this.lastHiddenFieldId - 1,
            name: "",
            capture_from_url_parameter: true,
            default_value: "",
            form_id: this.formID,
            form_variant_id: this.variantId,
          },
        ],
        deleteExisting: false,
      })
    },

    deleteHiddenField: function (id) {
      this.$store.dispatch(actionTypes.REMOVE_FORM_HIDDEN_FIELD, {
        id: id,
      })
    },

    updateHiddenFieldAttribute: function (value, field, attribute) {
      if (attribute === "name") {
        value = value ? value.trim() : value
      }
      this.$store.dispatch(actionTypes.UPDATE_FORM_HIDDEN_FIELD_ATTRIBUTE, {
        id: field.id,
        attributeValue: value,
        attribute: attribute,
      })
    },

    isDuplicateHiddenFieldName: function (hiddenField) {
      if (!hiddenField.name || !hiddenField.name.trim()) {
        return false
      }

      for (let step of this.steps) {
        for (let question of step.questions) {
          if (question.field_name === hiddenField.name) {
            return true
          }
        }
      }

      for (let hf of this.hiddenFields) {
        if (hf.name === hiddenField.name && hiddenField.id !== hf.id) {
          return true
        }
      }

      if (this.calculator && this.calculator.fieldName === hiddenField.name) {
        return true
      }

      return false
    },

    saveHiddenFields: function (formId, variantId) {
      let hiddenFields = this.hiddenFields.slice()
      for (let hf of hiddenFields) {
        hf.capture_from_url_parameter = hf.capture_from_url_parameter ? 1 : 0
        hf.form_id = formId
        hf.form_variant_id = variantId
      }
      this.$store
        .dispatch(actionTypes.SAVE_FORM_HIDDEN_FIELDS, {
          formId: formId,
          variantId: variantId,
          hiddenFields: hiddenFields,
        })
        .then((response) => {
          this.$store.dispatch(actionTypes.UPDATE_FORM_HIDDEN_FIELDS_SUCCESS, {
            hiddenFields: response.data.data,
            deleteExisting: true,
          })
        })
    },

    updateFormCalculator: function (value, field) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_CALCULATOR, {
        name: field,
        value: value,
      })
    },

    saveFooterText: function (text, isEnabled, id) {
      const footerData = {
        footerText: text,
        allStepsFooter: isEnabled,
      }
      this.$store
        .dispatch(actionTypes.UPDATE_FORM_FOOTER_TEXT, {
          formId: id,
          footerData,
        })
        .then((response) => {
          this.footer.text = text
          this.footer.allStepsFooter = isEnabled
        })
    },
    showSnackbar(message = null, isError = false) {
      const shouldBeGreen = this.saved && this.errorMessage.length === 0

      this.snackbar = {
        text: message || "Your form has been updated successfully.",
        show: true,
        color: shouldBeGreen ? 'primary-green' : (isError ? 'secondary-red' : 'primary-green')
      }
    }
  },

  computed: {
    ...mapGetters(["defaultFormVariantTheme"]),

    formID: function () {
      return parseInt(this.$route.params.id) || null
    },

    variantId: function () {
      return parseInt(this.$route.params.variantId) || null
    },

    formTitle: {
      get() {
        return this.$store.state.formBuildState.formTitle
      },
      set(newTitle) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_FORMTITLE, {
          title: newTitle,
        })
      },
    },

    choiceFormula: {
      get() {
        return this.$store.state.formBuildState.choiceFormula
      },
      set(newFormula) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_CHOICE_FORMULA, {
          choiceFormula: newFormula,
        })
      },
    },

    choiceFormulaTypeQuestions: function () {
      let sIndex = 1
      let choiceFormulaTypeQuestions = []

      // Create a set for fast lookup
      const eligibleTypes = new Set([
        questionTypesMap.SINGLE_CHOICE,
        questionTypesMap.MULTIPLE_CHOICE,
        questionTypesMap.NUMBER,
      ])

      for (let step of this.$store.state.formBuildState.steps) {
        let qIndex = 1

        let questions = _.cloneDeep(step.questions)
        questions = _.orderBy(questions, ["number"], ["asc"])

        for (let question of questions) {
          if (
            eligibleTypes.has(question.type) ||
            (question.type === questionTypesMap.RANGE &&
              question.skin.id === rangeSkinIds.SLIDER_SCALE)
          ) {
            choiceFormulaTypeQuestions.push(`S${sIndex}_Q${qIndex}`)
          }

          qIndex++
        }

        sIndex++
      }

      return choiceFormulaTypeQuestions
    },

    submitBtnText: function () {
      return this.action === "create" ? "Create " : "Save"
    },

    steps: function () {
      return this.$store.state.formBuildState.steps
    },

    hasAtLeastOneQuestion: function () {
      for (let step of this.steps) {
        if (step.questions.length > 0) {
          return true
        }
      }
      return false
    },

    hiddenFields: function () {
      return (
        this.$store.getters.formHiddenFields(this.formID, this.variantId) || []
      )
    },

    lastHiddenFieldId: function () {
      let lastHiddenFieldId = 0
      for (let hf of this.$store.state.formHiddenFields) {
        if (hf.id < lastHiddenFieldId) {
          lastHiddenFieldId = hf.id
        }
      }
      return lastHiddenFieldId
    },

    calculator: {
      get() {
        return this.$store.state.formBuildState.calculator
      },
    },

    validateCalculatorFieldName: function () {
      let errors = {}
      let formState = this.$store.state.formBuildState

      if (!formState) {
        return
      }

      for (let step of formState.steps) {
        for (let question of step.questions) {
          if (!question.field_name) {
            continue
          }

          if (question.field_name === this.calculator.fieldName) {
            errors.question = {
              fieldName: question.field_name,
              message: "field name is already used in one of the question.",
            }
          }
        }
      }

      if (this.calculator.fieldName === "gdpr") {
        errors.gdpr = {
          fieldName: "gdpr",
          message: "field name is reserved. Please choose another one.",
        }
      }

      return errors
    },

    currentTab: function () {
      return this.tab
    },

    isBuildTab: function () {
      return this.currentTab === formBuilderTabs.BUILD
    },

    isDesignTab: function () {
      return this.currentTab === formBuilderTabs.DESIGN
    },

    isEmbedTab: function () {
      return this.currentTab === formBuilderTabs.EMBED
    },
    isFormEndingsTab: function () {
      return this.currentTab === formBuilderTabs.FORMENDINGS
    },

    isDataLoaded: function () {
      return (
        this.getThemeComplete &&
        this.fetchHiddenFieldsComplete &&
        this.getThemesTemplateComplete
      )
    },
  },

  watch: {
    isDesignTab(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.scrollToTop()
      }
    },
    'footer.allStepsFooter': {
      handler(newVal) {
        this.footer.allStepsFooter = Boolean(newVal);
      },
      immediate: true
    }
  }
}
</script>
